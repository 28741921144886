/**
 * Defines constants for application
 */



define([
    'angular'
], function () {

    var config_module = angular.module('app.constants', [])
    var config_data = {
        'GENERAL_CONFIG': {
            'app_name': 'GlobalTax',
            'cache_templates' : true, //Heath added for dev push will change back to false for local development
            'security_enabled' : false,
            'root_' : $('body'),
            'app_version': '0.5',//--THIS COMES FROM THE DB NOW  (SERVER_CONFIG.configSettings.release_version)
            'app_debug': false,
            'base_url' : __env.apiUrl,
            'admin_api_url' : __env.adminApiUrl,
            'gtw_efile_api_url' : __env.gtwEfileApiUrl,
            'common_api_url':__env.commonApiUrl,
            'gold_sync_api_url' : __env.goldSyncApiUrl,
            'notification_api_url' : __env.notificationApiUrl, 
            'custom_api_url' : __env.customApiUrl, 
            'pdf_engine_api_url' : __env.pdfEngineApiUrl, 
            'bulk_pdf_engine_api_url' : __env.bulkPdfEngineApiUrl, 
            'api_link': 'http://localhost:8080/global-tax-syst',
            'site_link': 'http://localhost:8080/global-tax-syst/index.html',
            'keep_alive_url': __env.apiUrl+'/ajaxKeepAlive',
            'log_out_url': __env.apiUrl +'/logoutUser',
            'redirect_url': __env.apiUrl+ '/signout',
            'image_path' : 'assets/img/',
            'logout_idam_session' : 'https://fedsvc-stage.pwc.com/ofiss/public/HRD.aspx',
            'load-schema' : __env.loadSchemaApiUrl,
        }
    }

    angular.forEach(config_data,function(key,value) {
        config_module.constant(value,key);
    })

    return config_module;
});

